@import "../../Styles/global.scss";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
}

.required-label {
  color: $requiredColor;
  font-size: 0.9rem;
}

.page-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  .sidebar-wrap {
    position: relative;
    width: 22%;
    display: inline-block;
    height: 100vh;
    overflow: hidden;
  }
  .main-page-wrap {
    position: relative;
    width: 78%;
    display: inline-block;
    height: 100vh;
    overflow-y: scroll;
    background-color: $greyBackgroundColor;
  }
}

.page-main {
  background-color: $WhiteColor;
  margin: 20px;
  padding: 20px;
}

/*dark mode*/
.dark-mode .page-main,
.dark-mode .main-page-wrap {
  background-color: $darkMainPage;
  color: $WhiteColor;
}
/*dark mode*/

.mobile-side-bar {
  display: none;
  position: fixed;
  z-index: 999;
  right: 0;
}

.page-header-wrap {
  color: $textBlackColor;
  text-align: center;
  font-weight: 700;
  h2 {
    margin: 0;
  }
}

/*dark mode*/
.dark-mode .page-header-wrap {
  color: $WhiteColor;
}
/*dark mode*/

/*button*/
.pink-btn {
  margin: 10px auto;
  display: table;
  outline: 0;
  color: $WhiteColor;
  background-color: $backgroundPinkColor;
  border: 1px solid $backgroundBlueColor;
  padding: 5px 15px;
  &:focus {
    outline: 0;
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
/*button*/

/*media queries */
@media only screen and (min-width: $tab-min-width) and (max-width: $tab-max-width) {
  .sidebar-info-wrap {
    top: 15px;
  }
  .page-wrap .sidebar-wrap {
    width: 35%;
  }
  .page-wrap .main-page-wrap {
    width: 65%;
  }
}

@media only screen and (min-width: $tab-secondary-min-width) and (max-width: $tab-secondary-max-width) {
  .sidebar-info-wrap {
    top: 15px;
  }
  .page-wrap .sidebar-wrap {
    width: 40%;
  }
  .page-wrap .main-page-wrap {
    width: 60%;
  }
}

@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
  .page-wrap .mobile-sidebar {
    position: absolute;
    z-index: 999999999;
    top: 0;
    width: 80%;
    left: 0;
    &.mobile-sidebar-close {
      visibility: hidden;
      transition: all 0.25s ease;
      transform: translateX(-100%);
    }
    &.mobile-sidebar-active {
      visibility: visible;
      transition: all 0.25s ease;
      transform: translateX(0);
    }
  }
  .page-main {
    margin: 0px;
  }
  .page-wrap .main-page-wrap {
    width: 100%;
  }
  .mobile-side-bar {
    display: block;
  }
}
/*media queries */
