@mixin clearfix {
  &::after {
    content: " "; /* Older browser do not support empty content */
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }
}

@mixin marginTop($marginTop) {
  margin-top: $marginTop;
}

@mixin marginBotton($marginBottom) {
  margin-bottom: $marginBottom;
}

@mixin borderRadius($borderRadius) {
  border-radius: $borderRadius;
}

@mixin BackgroundImage($backgroundImgString) {
  background-image: url($backgroundImgString);
}

@mixin ComponentHeightWidth($width,$height) {
  height:$height;
  width:$width;
}
