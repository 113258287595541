parentElement {
  position: relative;
  background-size: 100% 100%;
}

verticleCenter {
  margin: 0 auto;
  display: table;
}

overflowHidden {
  overflow: hidden;
}

textAlignCenter {
  text-align: center;
}
