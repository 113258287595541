@import "./varaibles";
@import "./Mixins";
@import "./extends";

.vertical-center {
  @extend verticleCenter;
}

.mg-20 {
  @include marginTop(20px);
}

.mg-t-20 {
  @include marginTop(20px);
}

.parent {
  @extend parentElement;
}

.clearfix {
  @include clearfix();
}

h1 {
  font-size: 3.2em;
}

h2 {
  font-size: 1.8em;
}

.italic-font-style {
  font-style: italic;
}
