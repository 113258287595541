@import "../../../Styles/global.scss";

.side-nav {
  padding-top: 30px;
  color: $WhiteColor;
  background: rgba(2, 23, 59, 0.7);
  height: 100vh;
  width: 100%;
  top: 0;
  bottom: 0;
  position: absolute;
  &.close {
    visibility: hidden;
    transition: all 0.25s ease;
    transform: translateX(-100%);
  }
  &.active {
    visibility: visible;
    transition: all 0.25s ease;
    transform: translateX(0);
  }
}

/*dark mode*/
.dark-mode .side-nav {
  background: rgba(46, 46, 48, 0.7);
}
/*dark mode*/

@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
  .side-nav {
    display:none;
  }
}
