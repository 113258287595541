@import "../../shared/Styles/global.scss";

.hamburger-btn {
  span {
    background-color: $WhiteColor;
    &:before,
    &:after {
      background-color: $WhiteColor;
    }
  }
}

@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
  .hamburger-btn {
    span {
      background-color: $backgroundPinkColor;
      &:before,
      &:after {
        background-color: $backgroundPinkColor;
      }
    }
  }
}
