@import "../../Styles/global.scss";

.sidebar-info-wrap {
  position: absolute;
  z-index: 1000;
  top: 0;
  width: 100%;
  .sidebar-info-main {
    padding: 5px;
    img {
      @include ComponentHeightWidth(120px, 120px);
      @include borderRadius(50%);
    }
    .hamburger-wrap {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      @include marginTop(10px);
    }
    .profile-info-wrap {
      color: $WhiteColor;
      h3,
      h4 {
        text-align: center;
      }
      p {
        text-align: justify;
        font-size: 14px;
        padding: 0px 5px;
      }
    }
  }
}

.theme-wrap {
  position: absolute;
  top: 0;
  left: 0;
  margin: 25px 0px 0px;
  .react-toggle-track {
    background-color: #bb0b3b;
  }
  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #8d0f30;
  }
  .react-toggle--focus .react-toggle-thumb {
    box-shadow: 0px 0px 0px 0px $WhiteColor;
  }
}

.sidenav-icon-wrap {
  margin: 0px 5px;
  color: $WhiteColor;
}

.mobile-nav{
  display:none;
}

@media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
  .profile-info-wrap{
    h3{
      font-size:1.2em;
    }
    h4{
      font-size:1em;
    }
  }
  .sidebar-info-wrap .sidebar-info-main .profile-info-wrap p{
    font-size:13px;
  }
  .sidebar-info-wrap .sidebar-info-main .hamburger-wrap{
    display:none;
  }
  .mobile-nav{
    display:block;
  }
}
