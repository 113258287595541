@import "../../../Styles/global.scss";

.navigation-links > ul {
    list-style: none;
    > li {
      text-align: center;
      display: block;
      height: 5em;
      margin: 20px 0px;
      &:hover {
        a {
          color: $backgroundPinkColor;
          border-left: 2px solid $backgroundPinkColor;
          padding-left: 5px;
          font-weight: bold;
        }
      }
      > a {
        text-decoration: none;
        color: $WhiteColor;
        font-size: 22px;
        display: block;
        font-weight: bold;
        &.selected-link {
          color: $backgroundPinkColor;
          border-left: 2px solid $backgroundPinkColor;
        }
      }
    }
  }

  @media only screen and (min-width: $mobile-min-width) and (max-width: $mobile-max-width) {
    .navigation-links > ul{
        padding: 0;
        > li {
           height:0;
           margin: 5px 10px;
           display:inline-block;
           > a {
            font-size: 0.7em;   
            &.selected-link {
                color: $WhiteColor;
                border-left:0px;
                border-bottom: 2px solid $WhiteColor;
              }
           }
          &:hover{
             a{
              color: $WhiteColor; 
              border-left:0px;
              border-bottom: 2px solid $WhiteColor;
            }
            
          }
        }
    }
  }