@import "../../../Styles/global.scss";

.social-links{
    list-style:none;
    margin:10px;
    padding:0;
    li{
        display:inline-block;
        margin:0 5px;
    }
}