@import "../../../Styles/global.scss";
canvas {
  display: block;
  vertical-align: bottom;
} /* ---- particles.js container ---- */

#tsparticles {
  background: linear-gradient(
      180deg,
      $backgroundBlueColor 10%,
      $backgroundPinkColor
    )
    no-repeat;
  background: linear-gradient(
      180deg,
      $backgroundBlueColor 10%,
      $backgroundPinkColor
    )
    no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  z-index: -1;
  width: 100%;
} /* ---- stats.js ---- */
#tsparticles canvas {
  height: 100vh !important;
}
.count-particles {
  position: absolute;
  top: 48px;
  left: 0;
  width: 80px;
  font-size: 0.8em;
  text-align: left;
  text-indent: 4px;
  line-height: 14px;
  padding-bottom: 2px;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: bold;
}
.js-count-particles {
  font-size: 1.1em;
}
#stats,
.count-particles {
  -webkit-user-select: none;
  margin-top: 5px;
  margin-left: 5px;
}
#stats {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
}
.count-particles {
  border-radius: 0 0 3px 3px;
}

/*dark mode*/
.dark-mode #tsparticles {
  background: $darkSideColor;
}
/*dark mode*/
