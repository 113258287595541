@import "../../shared/Styles/global.scss";

.loader-wrap {
  position: relative;
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
}
