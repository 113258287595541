$textBlackColor: #000000;
$backgroundBlueColor: #02173e;
$backgroundPinkColor: #bf0c2b;
$WhiteColor: #ffffff;
$greyBackgroundColor: #f8f8f8;
$requiredColor: #e42f2f;
$skeletonLoaderBgColor: #ddd;
$skeletonLightTheme: #f2f2f2;
$skeletonDarkTheme: #444;
$skeletonDarkElement: #777;
$darkSideColor: #48494a;
$darkMainPage: #2e2e30;

/*responsiveness width*/

/*desktop*/
$desktop-min-width:1025px;
$desktop-max-width:1200px;
/*desktop*/

/*small screen*/
$small-min-width: 769px;
$small-max-width:1024px;
/*small screen*/

/*tab-secondary*/
$tab-secondary-min-width:820px;
$tab-secondary-max-width:1180px;
/*tab-secondary*/

/*tab width */
$tab-min-width:481px;
$tab-max-width:768px;
/*tab width */



/*mobile width */
$mobile-min-width: 320px;
$mobile-max-width: 480px;
/*mobile width */